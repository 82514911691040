import React from "react";
import AnimationOnScroll from "react-animate-on-scroll";
import ClassName from "../../Lib/ClassName";
import { images } from "../../constants";
import { Modal } from "../UiComponent";

type AppsType = {
  id: string;
  titleImage: string;
  imageM?: string;
  imageD: string;
  video?: string;
  mainImage: string;
  contant: string;
};

const data: AppsType[] = [
  {
    id: "1",
    titleImage: images.sunset,
    imageM: images.sunsetM,
    imageD: images.sunsetD,
    mainImage: "/img/work/2.png",
    contant:
      "The Sunset App provides a quick reference to precise sunset times in other, global locations. As sunset times play an important part in religious people's lives (e.g. the Jewish Sabbath and Holidays, Muslim Ramadan, and more), this user-friendly, allows you to see at a glance if it is a convenient time to reach out and serves as a valuable resource for individuals frequently contacting people across the globe.",
  },
  {
    id: "2",
    titleImage: images.homeworkkollel,
    imageM: images.homeworkkokwllM,
    imageD: images.homeworkkokwllD,
    mainImage: "/img/work/11.png",
    contant:
      "Homework Kollel is a reward system designed to motivate students to attend learning sessions or classes. It operates as an attendance-based scanning system using a membership card on an android device to scan. Students are then rewarded for their active participation for their attendance and activities encouraging consistent learning.",
    video: images.homeworkkokwllV,
  },
  {
    id: "3",
    titleImage: images.sveeva,
    imageM: images.sveevaM,
    imageD: images.sveevaD,
    mainImage: "/img/work/12.png",
    contant:
      "Sveeva is an event platform that seamlessly connects you with the latest happenings in your vicinity. Sveeva is a unique platform that combines communal, private, and public events into one organized calendar. Stay up-to-date with current events and effortlessly create and manage your events and follow your friends'activities and attendance,  through this user-friendly platform.",
  },
  {
    id: "4",
    titleImage: images.marqata,
    imageM: images.marqataM,
    imageD: images.marqataD,
    mainImage: "/img/work/13.png",
    contant:
      "Marqata is an exclusive FinTech app catering to interior design and architecture professionals. It serves as a unique marketplace connecting vetted experts with a curated selection of vendors, artisans, and craftsmen. With a proprietary payment platform ensuring secure transactions, Marqata offers substantial cash rewards on every purchase. It facilitates efficient project management by providing instant open-accounts with all marketplace vendors, enabling seamless management of project purchasing and invoicing.",
  },
  {
    id: "5",
    titleImage: images.simchagemach,
    imageM: images.simchagemachM,
    imageD: images.simchagemachD,
    mainImage: "/img/work/14.png",
    contant:
      "Gemach is an inventory system designed for gemach administrators managing or operating their own simcha gemach organization. This innovative app streamlines inventory management, order, check-in/check-out processess, allowing users to run their gemach operations effectively and easily keep track of their gemach's inventory live. It also serves as an availability system that keeps track of which items are available and which are not.",
  },
  {
    id: "6",
    titleImage: images.goDrive,
    imageM: images.goDriveM,
    imageD: images.goDriveD,
    mainImage: "/img/work/12.png",
    contant:
      "An app for a driving school app that transforms the learning to drive experience. The Drive School App is a user-friendly platform for teachers and students to set up and manage lessons and payments and keep track of students' progress. Everything is kept organized by the app's built-in calendar, assuring a smooth path to driving success.",
  },
];

const Work = () => {
  const [selectWork, setSelectWork] = React.useState<AppsType>();
  const [videoModal, setVideoModal] = React.useState(false);
  const [readMore, setReadMore] = React.useState(false);

  React.useEffect(() => {
    const defaultData = data[0];
    setSelectWork(defaultData);
  }, []);
  console.log(selectWork?.video);

  return (
    <div className="flex justify-center bg-work bg-no-repeat bg-cover bg-right-bottom">
      <div className="grid md:flex gap-5 px-10 md:px-20 md:pb-0 pb-16">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
          <div className="flex h-full items-center max-md:hidden">
            <div
              className={ClassName(
                "relative w-64 md:w-80 lg:w-[700px] xl:w-[750px] 2xl:w-[750px] max-md:h-[500px] xl:h-[530px] mt-8 mx-auto ",
                selectWork?.video ? " cursor-pointer" : ""
              )}
              onClick={() => {
                if (selectWork?.video) {
                  setVideoModal(true);
                }
              }}
            >
              <img
                className={ClassName("lg:h-[400px] xl:h-[500px] w-full")}
                src={selectWork?.imageD}
                alt="works_display"
              />
              <img
                src={selectWork?.imageM}
                className="absolute h-[90%] right-[40px] md:bottom-[-5px] xl:bottom-[10px]"
                alt=""
              />
              {selectWork?.video && (
                <img
                  src={images.play}
                  alt=""
                  className="absolute h-[50px] right-[45%] top-[45%] md:bottom-[-5px] xl:bottom-[10px]"
                />
              )}
            </div>
          </div>

          <div className="flex justify-center w-64 md:w-80 lg:w-[700px] xl:w-[800px] max-md:h-[500px] xl:h-[530px] mt-8 mx-auto md:hidden">
            {selectWork?.imageM && (
              <div
                className={ClassName(
                  "relative",
                  selectWork?.video ? " cursor-pointer" : ""
                )}
                onClick={() => {
                  if (selectWork?.video) {
                    setVideoModal(true);
                  }
                }}
              >
                <img
                  src={selectWork?.imageM}
                  className=" h-[300px] object-cover"
                  alt=""
                />
                {selectWork?.video && (
                  <img
                    src={images.play}
                    alt=""
                    className="absolute h-[50px] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] md:bottom-[-5px] xl:bottom-[10px]"
                  />
                )}
              </div>
            )}
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
          <div className="text-white md:py-16 max-w-[500px]">
            <div>
              <button
                className="my-2 p-1 px-2 rounded-full border border-white md:hidden"
                onClick={() => setReadMore((o) => !o)}
              >
                {"Read more >"}
              </button>

              <p
                className={`md:text-sm  xl:text-lg mb-5 md:hidden ${
                  !readMore && "overflowLine"
                }`}
              >
                {selectWork?.contant}
              </p>

              <h2 className="font-Poppins font-bold md:text-3xl 2xl:text-5xl mb-5 max-w-xl">
                Some of our memorable Apps
              </h2>
              <p className="font-Manrope font-medium">
                Building for the future
              </p>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 gap-2 my-10 ">
              {data.map((list, index) => {
                return (
                  <div
                    className={ClassName(
                      selectWork?.id === list?.id
                        ? "bg-blue/30 rounded-lg w-fit mx-auto cursor-pointer"
                        : ""
                    )}
                  >
                    <img
                      key={index}
                      alt="Work portfolio"
                      src={list?.titleImage}
                      className="w-16 aspect-square mx-auto rounded-[15px]"
                      onClick={() => setSelectWork(list)}
                    />
                  </div>
                );
              })}
            </div>

            <p
              className={`md:text-sm  xl:text-lg max-md:hidden ${
                !readMore && "overflowLine"
              }`}
            >
              {selectWork?.contant}
            </p>
            <button
              className="my-2 p-1 px-2 rounded-full border border-white max-md:hidden"
              onClick={() => setReadMore((o) => !o)}
            >
              {"Read more >"}
            </button>
          </div>
        </AnimationOnScroll>
      </div>
      <Modal
        // title="Contact Us"
        maxWidth="xl"
        show={videoModal}
        onClose={() => setVideoModal(false)}
        showCancel={true}
      >
        <div className="px-10 py-16 bg-white text-navyblue">
          <video
            // width="400px"
            // height="240"
            className="w-[800px] h-[500px]"
            controls
          >
            <source src={selectWork?.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default Work;
