const baseImgPath = "/img/work/";

export const images = {
  goDrive: baseImgPath + "driveSchool.png",
  goDriveD: baseImgPath + "goDriveD.png",
  goDriveM: baseImgPath + "goDriveM.png",

  sunset: baseImgPath + "sunset.png",
  sunsetD: baseImgPath + "sunSetD.png",
  sunsetM: baseImgPath + "sunSetM.png",

  homeworkkollel: baseImgPath + "homeworkkollel.png",
  homeworkkokwllD: baseImgPath + "homeworkkokwllD.png",
  homeworkkokwllM: baseImgPath + "homeworkkokwllM.png",
  homeworkkokwllV: baseImgPath + "HomeworkKollel_V.mp4",

  sveeva: baseImgPath + "sveeva.png",
  sveevaD: baseImgPath + "sveevaD.png",
  sveevaM: baseImgPath + "sveevaM.png",

  marqata: baseImgPath + "marqata.png",
  marqataD: baseImgPath + "marqataD.png",
  marqataM: baseImgPath + "marqataM.png",

  simchagemach: baseImgPath + "simchagemach.png",
  simchagemachD: baseImgPath + "gemchaD.png",
  simchagemachM: baseImgPath + "gemchaM.png",

  play: baseImgPath + "play.png",
};
