import React from "react";
import {
  CheckIcon,
  DataAutomationIcon,
  DataIntegrationIcon,
  DataIntelligenceIcon,
} from "../Icons";
import AnimationOnScroll from "react-animate-on-scroll";

const data = [
  {
    title: "Data Integration",
    contant: [
      "Data Processes and Workflows",
      "Data Migration and Warehousing",
      "Onboarding, Forms, Dashboards and Alerts",
    ],
    icon: DataIntegrationIcon,
  },
  {
    title: "Data Automation",
    contant: [
      "Real-time API Analytics",
      "Business/Mobile Portals and Apps",
      "Blended Process Redesigns",
      "Intelligent Bots",
    ],
    icon: DataAutomationIcon,
  },
  {
    title: "Data Intelligence",
    contant: [
      "Data Trends and Projections",
      "3rd Party Data",
      "Big Data Business Insights",
    ],
    icon: DataIntelligenceIcon,
  },
];

const Service = () => {
  return (
    <div className="px-10 md:px-20 py-16 max-md:py-0 bg-white text-navyblue">
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
        <div className="text-center my-10">
          <h2 className="font-Poppins font-bold text-3xl md:text-5xl lg:text-6xl xl:text-7xl mb-5">
            Help your Data Live Longer
          </h2>
          <p className="font-Manrope font-medium text-sx max-w-3xl m-auto">
            Our Services
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-6xl mx-auto my-10">
          {data.map((list, index) => {
            return (
              <div
                className="bg-navyblue text-white rounded-md shadow-xl px-10 py-6 group hover:bg-white hover:text-navyblue"
                key={index}
              >
                <h2 className="font-Poppins font-extrabold text-darkblue group-hover:text-navyblue uppercase text-xl mb-5">
                  level {index}
                </h2>
                <div className="flex gap-3 md:gap-6 items-center">
                  <div className="h-14 w-14 mb-5 rounded-full flex justify-center items-center bg-darkblue text-white group-hover:bg-navyblue">
                    <list.icon className="" />
                  </div>
                  <h2 className="font-Vollkorn font-extrabold uppercase text-lg md:text-xl mb-2 group-hover:text-darkblue">
                    {list.title}
                  </h2>
                </div>
                <div className="text-sm space-y-2 my-3">
                  {list.contant.map((list, i) => {
                    return (
                      <p
                        className="flex items-baseline gap-3 group-hover:text-darkblue"
                        key={i}
                      >
                        <span>
                          <CheckIcon className="group-hover:text-navyblue" />
                        </span>{" "}
                        {list}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default Service;
