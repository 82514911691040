import React from "react";
import { useNavigate } from "react-router-dom";
import { BrandIcon, RightArrowIcon } from "../Icons";
import { Button } from "../UiComponent";
import AnimationOnScroll from "react-animate-on-scroll";

const Banner = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="md:px-20 py-6 pb-20 pt-0 bg-darkblue text-white">
        {/* max-lg:hidden */}
        <div className="grid lg:flex justify-between items-center max-lg:hidden">
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
            <div className="order-2	lg:order-1">
              <h3 className="flex items-center font-Poppins text-3xl">
                <span className="h-0.5 w-8 bg-white mr-2"></span> Hi,
              </h3>
              <h2 className="font-Poppins font-extralight text-5xl md:text-4xl lg:text-5xl xl:text-7xl">
                We're
              </h2>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__rubberBand"
              >
                <h2 className="font-Poppins font-bold text-5xl md:text-7xl lg:text-7xl xl:text-8xl mb-7">
                  Datamatic
                </h2>
              </AnimationOnScroll>
              <p className="max-w-xs font-Manrope font-medium">
                We create cost-effective MVPs, custom apps and automated
                business solutions.
              </p>
              <div className="flex my-5">
                <span className="h-0.5 w-8 bg-white mr-2"></span>
              </div>
              <Button outlined onClick={() => navigate(`/?view=contact`)}>
                Get in touch
                <RightArrowIcon className="ml-2" />
              </Button>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn="animate__pulse">
            <div className="md:h-96 lg:h-[34rem] xl:h-[38rem] w-auto order-1 lg:order-2">
              <img
                className="h-full w-fill object-cover"
                src="/img/banner/1.png"
                alt=""
              />
            </div>
          </AnimationOnScroll>
        </div>
        {/* md:hidden */}
        <div className="grid justify-between items-center lg:hidden relative pt-4">
          <div className="text-white m-6 absolute top-0">
            <BrandIcon height={40} width={140} />
          </div>
          {/* <AnimationOnScroll animateOnce={true} animateIn="animate__pulse"> */}
          <div className="md:h-96 lg:h-[34rem] xl:h-[38rem] w-auto">
            <img
              className="h-full w-full object-cover"
              src="/img/banner/1.png"
              alt=""
            />
          </div>
          {/* </AnimationOnScroll> */}
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
            <div className="order-2	lg:order-1 px-5 mt-[-30px]">
              <h3 className="flex items-center font-Poppins text-xs">
                <span className="h-0.5 w-8 bg-white mr-2"></span> Hi,
              </h3>
              <h2 className="font-Poppins font-extralight text-5xl md:text-4xl lg:text-5xl xl:text-7xl">
                We're
              </h2>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__rubberBand"
              >
                <h2 className="font-Poppins font-bold text-5xl md:text-7xl lg:text-7xl xl:text-8xl mb-7">
                  Datamatic
                </h2>
              </AnimationOnScroll>
              <p className="max-w-xs font-Manrope font-medium">
                We create cost-effective MVPs, custom apps and automated
                business solutions.
              </p>
              <div className="flex my-5">
                <span className="h-0.5 w-8 bg-white mr-2"></span>
              </div>
              <Button
                outlined
                onClick={() => navigate(`/?view=contact`)}
                className="w-[100%]"
              >
                Get in touch
                <RightArrowIcon className="ml-2 w-5" />
              </Button>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="bg-navyblue text-white relative">
        <div className="oval-top bg-navyblue md:h-16 h-10 absolute md:-top-16 -top-9"></div>
        <div className="px-5 md:px-20 py-20 bg-banner bg-contain bg-no-repeat bg-right-bottom">
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <h2 className="font-Vollkorn font-bold text-5xl mb-6">
              Work Smarter + Faster
            </h2>
            <p className="font-Manrope font-bold mb-4">
              Take the longer/shorter way not the shorter/longer way!
            </p>
            <p className="max-w-2xl lg:font-Manrope font-medium text-white/60 max-md:text-white text-sm">
              At Datamatic we are obsessive about using data and technology to
              solve problems and help organizations grow. But we also know that
              no software or app will succeed without the right training and
              processes for your team to manage it.
            </p>
          </AnimationOnScroll>
        </div>
      </div>
    </>
  );
};

export default Banner;
