import "animate.css/animate.min.css";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./Routes/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <AppRoutes />
        <Toaster position="top-center" reverseOrder={false} />
      </Router>
    </>
  );
}

export default App;
