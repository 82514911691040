import React from "react";

const RightArrow = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="15"
      viewBox="0 0 31 15"
      fill="currentColor"
      {...props}
    >
      <path
        d="M30.0605 8.31917C30.451 7.92864 30.451 7.29548 30.0605 6.90495L23.6965 0.540993C23.306 0.150468 22.6728 0.150468 22.2823 0.540993C21.8918 0.931517 21.8918 1.56468 22.2823 1.95521L27.9392 7.61206L22.2823 13.2689C21.8918 13.6594 21.8918 14.2926 22.2823 14.6831C22.6728 15.0737 23.306 15.0737 23.6965 14.6831L30.0605 8.31917ZM0.646484 8.61206L29.3534 8.61206V6.61206L0.646484 6.61206L0.646484 8.61206Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrow;
