import React from "react";

const Check = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      {...props}
    >
      <path
        d="M1 5.1001L4.97059 8.6001L13 1.6001"
        stroke="#5956E9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Check;
