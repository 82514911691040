import React from "react";

const DataIntegration = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="currentColor"
      {...props}
    >
      <path
        d="M5.875 12.0001H4V5.75006H1.5V3.25006H9V5.75006H6.5V9.24381L18.7663 5.95756L18.0413 3.25006H14V0.75006H18.9725C19.2523 0.743886 19.5261 0.831813 19.7501 0.999756C19.974 1.1677 20.1351 1.40594 20.2075 1.67631L22.1487 8.92131L19.7337 9.56756L19.4137 8.37256L5.875 12.0001ZM5.25 20.7501C5.91304 20.7501 6.54893 20.4867 7.01777 20.0178C7.48661 19.549 7.75 18.9131 7.75 18.2501C7.75 17.587 7.48661 16.9511 7.01777 16.4823C6.54893 16.0135 5.91304 15.7501 5.25 15.7501C4.58696 15.7501 3.95107 16.0135 3.48223 16.4823C3.01339 16.9511 2.75 17.587 2.75 18.2501C2.75 18.9131 3.01339 19.549 3.48223 20.0178C3.95107 20.4867 4.58696 20.7501 5.25 20.7501ZM5.25 23.2501C3.92392 23.2501 2.65215 22.7233 1.71447 21.7856C0.776784 20.8479 0.25 19.5761 0.25 18.2501C0.25 16.924 0.776784 15.6522 1.71447 14.7145C2.65215 13.7768 3.92392 13.2501 5.25 13.2501C6.57608 13.2501 7.84785 13.7768 8.78553 14.7145C9.72322 15.6522 10.25 16.924 10.25 18.2501C10.25 19.5761 9.72322 20.8479 8.78553 21.7856C7.84785 22.7233 6.57608 23.2501 5.25 23.2501ZM21.5 20.7501C22.4946 20.7501 23.4484 20.355 24.1517 19.6517C24.8549 18.9485 25.25 17.9946 25.25 17.0001C25.25 16.0055 24.8549 15.0517 24.1517 14.3484C23.4484 13.6451 22.4946 13.2501 21.5 13.2501C20.5054 13.2501 19.5516 13.6451 18.8483 14.3484C18.1451 15.0517 17.75 16.0055 17.75 17.0001C17.75 17.9946 18.1451 18.9485 18.8483 19.6517C19.5516 20.355 20.5054 20.7501 21.5 20.7501ZM21.5 23.2501C19.8424 23.2501 18.2527 22.5916 17.0806 21.4195C15.9085 20.2474 15.25 18.6577 15.25 17.0001C15.25 15.3425 15.9085 13.7527 17.0806 12.5806C18.2527 11.4085 19.8424 10.7501 21.5 10.7501C23.1576 10.7501 24.7473 11.4085 25.9194 12.5806C27.0915 13.7527 27.75 15.3425 27.75 17.0001C27.75 18.6577 27.0915 20.2474 25.9194 21.4195C24.7473 22.5916 23.1576 23.2501 21.5 23.2501Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataIntegration;
