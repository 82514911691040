import React from "react";

const DataIntelligence = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="currentColor"
      {...props}
    >
      <path
        d="M12.0975 9.53999L6.46002 0.69999L8.87377 0.0524902L17.5625 8.07749L24.14 6.31499C24.6204 6.18636 25.1322 6.25383 25.5628 6.50255C25.9935 6.75127 26.3076 7.16087 26.4363 7.64124C26.5649 8.12161 26.4974 8.63341 26.2487 9.06404C26 9.49467 25.5904 9.80886 25.11 9.93749L6.39502 14.95L5.42502 11.3275L5.72627 11.2462L8.81002 14.3025L5.52752 15.1825C5.25835 15.2547 4.97278 15.2348 4.71619 15.1261C4.45959 15.0174 4.24667 14.8261 4.11127 14.5825L0.83252 8.67499L2.64377 8.18999L5.72627 11.2462L12.0975 9.53874V9.53999ZM4.00002 18.75H24V21.25H4.00002V18.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataIntelligence;
