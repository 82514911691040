import React from "react";
import { Modal } from "../UiComponent";
import { CallIcon, MessageIcon } from "../Icons";
import { useSearchParams } from "react-router-dom";
import AnimationOnScroll from "react-animate-on-scroll";
import { useForm } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xdojdkgd");
  const [contactModal, setContactModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    view: "",
  }) as any;
  const activeView = searchParams.get("view");

  const paramsSearch = React.useCallback(() => {
    switch (activeView) {
      case "contact":
        setContactModal(true);
        break;
    }
  }, [activeView]);

  const onContactModelCloseHandle = () => {
    setContactModal(false);
    setSearchParams({});
  };

  React.useEffect(() => {
    paramsSearch();
  }, [paramsSearch, state]);

  return (
    <>
      <div
        id="contact"
        className="flex justify-center bg-contact_bg bg-contain bg-no-repeat bg-left-bottom"
      >
        <div className="max-w-[1400px] md:px-20 py-16 max-md:py-0">
          <div className="px-10 py-16 bg-white shadow-md text-navyblue rounded-lg">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 space-y-5">
                <div>
                  <div className="mb-10">
                    <h2 className="font-Poppins font-bold text-4xl mb-3">
                      Get in Touch
                    </h2>
                    <p className="font-Manrope font-medium">
                      Take your business to the next level with Datamatic. We
                      offer data automation, analytics, and custom software
                      solutions. Our dedicated team is just a click away, ready
                      to build strong relationships and help you achieve your
                      goals. Contact us today at info@datamatic.com or use the
                      form below. Let's make your business thrive together.
                    </p>
                  </div>
                  <div className="font-Manrope font-bold space-y-2">
                    <div className="flex gap-3 items-center">
                      <CallIcon className="text-darkblue h-5 w-5" />{" "}
                      <h2>718 715 4084</h2>
                    </div>
                    <div className="flex gap-3 items-center">
                      <MessageIcon className="text-darkblue h-5 w-5" />{" "}
                      <h2>info@datamatic.us</h2>
                    </div>
                  </div>
                </div>
                <hr className="lg:hidden block" />
                <form
                  onSubmit={handleSubmit}
                  className="space-y-4 font-Manrope font-normal"
                >
                  <div className="grid grid-cols-1">
                    <input
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <input
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      type="text"
                      name="company"
                      id="company"
                      placeholder="Company"
                      required
                    />
                    <input
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      type="text"
                      name="role"
                      id="role"
                      placeholder="Position / Role"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <input
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      required
                    />
                    <input
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      type="number"
                      name="p_no"
                      id="p_no"
                      placeholder="Phone #"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-1">
                    <textarea
                      className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                      name="message"
                      id="message"
                      placeholder="Drop us a line"
                      required
                    ></textarea>
                  </div>
                  <div className="grid grid-cols-1">
                    {state.succeeded ? (
                      <h2 className="text-blue font-Vollkorn font-extrabold text-xl text-center">
                        Our team member will contact you soon.
                      </h2>
                    ) : (
                      <button
                        type="submit"
                        disabled={state.submitting}
                        className="border-0 bg-darkblue text-white font-Manrope font-bold rounded-md shadow py-3 px-5"
                      >
                        Send
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>

      <Modal
        // title="Contact Us"
        maxWidth="xl"
        show={contactModal}
        onClose={onContactModelCloseHandle}
        showCancel={true}
      >
        <div className="px-10 py-16 bg-white text-navyblue">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 space-y-5">
            <div>
              <div className="mb-10">
                <h2 className="font-Poppins font-bold text-4xl mb-3">
                  Get in Touch
                </h2>
                <p className="font-Manrope font-medium">
                  Take your business to the next level with Datamatic. We offer
                  data automation, analytics, and custom software solutions. Our
                  dedicated team is just a click away, ready to build strong
                  relationships and help you achieve your goals. Contact us
                  today at info@datamatic.com or use the form below. Let's make
                  your business thrive together.
                </p>
              </div>
              <div className="font-Manrope font-bold space-y-2">
                <div className="flex gap-3 items-center">
                  <CallIcon className="text-darkblue h-5 w-5" />{" "}
                  <h2>+1 555 505 5050</h2>
                </div>
                <div className="flex gap-3 items-center">
                  <MessageIcon className="text-darkblue h-5 w-5" />{" "}
                  <h2>info@datamatic.us.com</h2>
                </div>
              </div>
            </div>
            <hr className="lg:hidden block" />
            <form
              onSubmit={handleSubmit}
              className="space-y-4 font-Manrope font-normal"
            >
              <div className="grid grid-cols-2 gap-5">
                <input
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="email"
                  required
                />
                <input
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  type="number"
                  name="p_no"
                  id="p_no"
                  placeholder="phone #"
                  required
                />
              </div>
              <div className="grid grid-cols-1">
                <input
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="name"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-5">
                <input
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  type="text"
                  name="company"
                  id="company"
                  placeholder="company"
                  required
                />
                <input
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  type="text"
                  name="role"
                  id="role"
                  placeholder="position/role"
                  required
                />
              </div>
              <div className="grid grid-cols-1">
                <textarea
                  className="border border-[#EBEAED] rounded-md shadow p-2 px-5 outline-none"
                  name="message"
                  id="message"
                  placeholder="Drop us a line"
                  required
                ></textarea>
              </div>
              <div className="grid grid-cols-1">
                {state.succeeded ? (
                  <h2 className="text-blue font-Vollkorn font-extrabold text-xl text-center">
                    Our team member will contact you soon.
                  </h2>
                ) : (
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="border-0 bg-darkblue text-white font-Manrope font-bold rounded-md shadow py-3 px-5"
                  >
                    Send
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contact;
