import React from "react";
import {
  BrandIcon,
  InstagramIcon,
  RightArrowIcon,
  TwitterIcon,
} from "../../Icons";
import { Button } from "../../UiComponent";
import { useNavigate } from "react-router-dom";
import AnimationOnScroll from "react-animate-on-scroll";

const Footer = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="relative bg-navyblue text-white">
        <div className="bg-footer_bg bg-contain bg-no-repeat bg-right-bottom">
          <div className="lg:flex justify-between px-10 md:px-20 py-20">
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInLeft"
            >
              <div>
                <h2 className="font-Vollkorn font-bold text-5xl mb-4">
                  Let your data flow.
                </h2>
                <p className="max-w-xl font-Manrope font-medium text-white/60 text-sm">
                  Our low-cost solutions combine your business processes with
                  intuitive apps, creating a smooth operation for your team that
                  is easy to manage and scales fast.
                </p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInRight"
            >
              <div className="lg:mt-0 mt-10">
                <Button outlined onClick={() => navigate(`/?view=contact`)}>
                  Get in touch
                  <RightArrowIcon className="ml-2" />
                </Button>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
        <div className="oval-bottom bg-navyblue md:h-16 h-10 absolute md:-bottom-16 -bottom-9"></div>
      </div>
      <div className="px-10 md:px-20 py-6 pt-20 bg-darkblue text-white flex items-center justify-between border-b-2 border-white/80">
        <div>
          <BrandIcon height={50} width={200} className="mx-auto" />
        </div>
        {/* <div className="flex md:justify-end justify-center gap-5 items-end text-white">
          <TwitterIcon className="cursor-pointer hover:text-navyblue" />
          <InstagramIcon className="cursor-pointer hover:text-navyblue" />
        </div> */}
      </div>
      <div className="px-10 md:px-20 py-6 bg-darkblue text-white font-Poppins text-sm">
        <div className="text-center">
          <h2>©2022, Datamatic.us, All rights reserved.</h2>
        </div>
      </div>
    </>
  );
};

export default Footer;
