import React from "react";
import { Button } from "../../UiComponent";
import { HashLink } from "react-router-hash-link";
import { useOutsideClick } from "../../../Lib/Hooks";
import { BrandIcon, HamburgerIcon, RightArrowIcon } from "../../Icons";

const Navbar = () => {
  const ref = React.useRef<any>();
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);

  useOutsideClick(ref, () => {
    if (isToggleOpen) {
      setIsToggleOpen(false);
    }
  });

  return (
    <>
      <header
        ref={ref}
        className="relative flex justify-between items-center w-full px-10 md:px-20 py-6 bg-darkblue text-white max-lg:hidden"
      >
        {isToggleOpen && (
          <div className="absolute top-0 left-0 h-screen w-52 bg-darkblue shadow-xl text-white z-50">
            <div className="px-7 py-10 space-y-7">
              <div className="text-white">
                <BrandIcon height={40} width={160} />
              </div>
              <ul className="grid space-y-5 text-white">
                {/* {navigation.map((item, index) => {
                  return (
                    <li key={index} className="">
                      <HashLink key={index} smooth to={`#${item?.path}`}>
                        {item?.title}
                      </HashLink>
                    </li>
                  );
                })} */}
                <HashLink smooth to={`#contact`}>
                  <Button outlined>
                    Get in touch
                    <RightArrowIcon className="ml-2" />
                  </Button>
                </HashLink>
              </ul>
            </div>
          </div>
        )}
        <div className="text-white hidden lg:block">
          <BrandIcon height={60} width={260} />
        </div>
        <div
          className="text-white block lg:hidden"
          onClick={() => setIsToggleOpen(true)}
        >
          <HamburgerIcon />
        </div>

        <div>
          <ul className="hidden lg:flex gap-12 items-center text-white font-Manrope font-medium">
            {/* {navigation.map((item, index) => {
              return (
                <li key={index} className="">
                  <HashLink key={index} smooth to={`#${item?.path}`}>
                    {item?.title}
                  </HashLink>
                </li>
              );
            })} */}
            {/* <HashLink smooth to={`#contact`}>
              <Button outlined>
                Contact Us
                <RightArrowIcon className="ml-2" />
              </Button>
            </HashLink> */}
            {/* <Button outlined onClick={() => navigate(`/?view=contact`)}>
              Contact Us
            </Button> */}
          </ul>
        </div>
      </header>
    </>
  );
};

export default Navbar;
