import React from "react";
import { Banner, Contact, Service, Soluction, Work } from "../Components/Home";

const Landing = () => {
  return (
    <div className="">
      <Banner />
      <Soluction />
      <Work />
      <Service />
      <Contact />
    </div>
  );
};

export default Landing;
