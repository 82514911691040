import React from "react";

const Discover = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="currentColor"
      {...props}
    >
      <path
        d="M13 25.5C6.09625 25.5 0.5 19.9037 0.5 13C0.5 6.09625 6.09625 0.5 13 0.5C19.9037 0.5 25.5 6.09625 25.5 13C25.5 19.9037 19.9037 25.5 13 25.5ZM10.1375 22.5837C8.90427 19.9679 8.18984 17.1378 8.03375 14.25H3.0775C3.32073 16.1736 4.1174 17.9852 5.37071 19.4646C6.62402 20.9441 8.28002 22.0277 10.1375 22.5837ZM10.5375 14.25C10.7262 17.2987 11.5975 20.1625 13 22.69C14.4404 20.0957 15.2818 17.2118 15.4625 14.25H10.5375ZM22.9225 14.25H17.9662C17.8102 17.1378 17.0957 19.9679 15.8625 22.5837C17.72 22.0277 19.376 20.9441 20.6293 19.4646C21.8826 17.9852 22.6793 16.1736 22.9225 14.25ZM3.0775 11.75H8.03375C8.18984 8.86223 8.90427 6.03211 10.1375 3.41625C8.28002 3.97235 6.62402 5.05594 5.37071 6.53537C4.1174 8.0148 3.32073 9.82638 3.0775 11.75ZM10.5387 11.75H15.4613C15.2809 8.78825 14.44 5.90442 13 3.31C11.5596 5.90431 10.7182 8.78815 10.5375 11.75H10.5387ZM15.8625 3.41625C17.0957 6.03211 17.8102 8.86223 17.9662 11.75H22.9225C22.6793 9.82638 21.8826 8.0148 20.6293 6.53537C19.376 5.05594 17.72 3.97235 15.8625 3.41625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Discover;
