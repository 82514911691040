import React from "react";

const Build = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="currentColor"
      {...props}
    >
      <path
        d="M27.75 8.99988L18.9112 17.8386L17.1438 16.0711L24.215 8.99988L17.1438 1.92863L18.9112 0.161133L27.75 8.99988ZM3.785 8.99988L10.8562 16.0711L9.08875 17.8386L0.25 8.99988L9.08875 0.161133L10.8562 1.92863L3.785 8.99988Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Build;
