import React from "react";

const Design = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        d="M5.81245 14.6511L3.16245 17.3036L6.69745 20.8386L20.8387 6.69606L17.3037 3.16231L14.6499 5.81231L16.4187 7.57981L14.6499 9.34981L12.8824 7.58106L11.1149 9.34981L12.8824 11.1173L11.1149 12.8848L9.34745 11.1173L7.5787 12.8848L9.34745 14.6523L7.5787 16.4211L5.81245 14.6498V14.6511ZM18.1874 0.509811L23.4899 5.81231C23.7243 6.04672 23.8559 6.36461 23.8559 6.69606C23.8559 7.02752 23.7243 7.3454 23.4899 7.57981L7.57995 23.4898C7.34554 23.7241 7.02765 23.8558 6.6962 23.8558C6.36474 23.8558 6.04686 23.7241 5.81245 23.4898L0.509948 18.1873C0.275609 17.9529 0.143965 17.635 0.143965 17.3036C0.143965 16.9721 0.275609 16.6542 0.509948 16.4198L16.4199 0.509811C16.6544 0.275472 16.9722 0.143828 17.3037 0.143828C17.6352 0.143828 17.953 0.275472 18.1874 0.509811ZM14.6499 19.9536L16.4187 18.1861L19.2212 20.9898H20.9887V19.2223L18.1862 16.4186L19.9537 14.6511L23.2499 17.9461V23.2498H17.9474L14.6512 19.9536H14.6499ZM4.04495 9.34731L0.508698 5.81231C0.392477 5.69622 0.300279 5.55836 0.237374 5.40661C0.174468 5.25486 0.14209 5.09221 0.14209 4.92794C0.14209 4.76367 0.174468 4.60101 0.237374 4.44926C0.300279 4.29751 0.392477 4.15965 0.508698 4.04356L4.04495 0.508561C4.27936 0.274222 4.59724 0.142578 4.9287 0.142578C5.26015 0.142578 5.57804 0.274222 5.81245 0.508561L9.34995 4.04356L7.57995 5.81231L4.9287 3.15981L3.1612 4.92856L5.81245 7.57856L4.04495 9.34731Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Design;
