import React from "react";
import AnimationOnScroll from "react-animate-on-scroll";
import { BuildIcon, DesignIcon, DiscoverIcon } from "../Icons";

const data = [
  {
    title: "1. Discover",
    contant:
      "Isolate bottlenecks and uncover new opportunities by capturing your full data lifecycles and key business processes.",
    icon: DiscoverIcon,
  },
  {
    title: "2. DESIGN",
    contant:
      "Create simpler and faster blended workflows, with input interfaces and dashboards your team really needs.",
    icon: DesignIcon,
  },
  {
    title: "3. BUILD",
    contant:
      "Give your team beautiful, simple apps to share and find the exact data they need in real-time.",
    icon: BuildIcon,
  },
];

const Soluction = () => {
  return (
    <div className="px-5 md:px-20 py-16 bg-white text-navyblue">
      <div className="text-center my-10">
        <h2 className="font-Poppins font-bold text-3xl md:text-5xl lg:text-6xl xl:text-7xl mb-5">
          Let your staff and customers fall in love.
        </h2>
        <p className="font-Manrope font-medium text-sx max-w-3xl m-auto">
          Apps need people. We implement simple training and processes and even
          work with existing legacy software so your app will gain traction.
        </p>
      </div>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-6xl mx-auto my-10">
          {data.map((list, index) => {
            return (
              <div
                className="border border-[#EBEAED] rounded-md shadow px-4 py-6 group hover:bg-darkblue text-black hover:text-white hover:shadow-xl"
                key={index}
              >
                <div className="h-14 w-14 mx-auto mb-5 rounded-full flex justify-center items-center bg-darkblue text-white group-hover:bg-white">
                  <list.icon className="group-hover:text-darkblue" />
                </div>
                <h2 className="font-Vollkorn font-extrabold uppercase text-2xl text-center mb-2">
                  {list.title}
                </h2>
                <p className="font-Manrope font-normal text-center">
                  {list.contant}
                </p>
              </div>
            );
          })}
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default Soluction;
