import React from "react";

const DataAutomation = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="currentColor"
      {...props}
    >
      <path
        d="M10.2063 16.5C10.0577 17.5429 9.53777 18.4971 8.7421 19.1874C7.94643 19.8778 6.9284 20.2578 5.875 20.2578C4.8216 20.2578 3.80357 19.8778 3.0079 19.1874C2.21223 18.4971 1.69235 17.5429 1.54375 16.5H0.25V1.5C0.25 1.16848 0.381696 0.850537 0.616116 0.616117C0.850537 0.381696 1.16848 0.25 1.5 0.25H19C19.3315 0.25 19.6495 0.381696 19.8839 0.616117C20.1183 0.850537 20.25 1.16848 20.25 1.5V4H24L27.75 9.07V16.5H25.2062C25.0576 17.5429 24.5378 18.4971 23.7421 19.1874C22.9464 19.8778 21.9284 20.2578 20.875 20.2578C19.8216 20.2578 18.8036 19.8778 18.0079 19.1874C17.2122 18.4971 16.6924 17.5429 16.5438 16.5H10.2063ZM17.75 2.75H2.75V12.8125C3.2432 12.309 3.85075 11.9321 4.52094 11.7139C5.19113 11.4957 5.90412 11.4426 6.59922 11.5592C7.29433 11.6758 7.95097 11.9587 8.51326 12.3836C9.07556 12.8086 9.52686 13.3631 9.82875 14H16.9212C17.1313 13.5588 17.4125 13.1575 17.75 12.8125V2.75ZM20.25 10.25H25.25V9.89375L22.74 6.5H20.25V10.25ZM20.875 17.75C21.3724 17.75 21.8495 17.5524 22.2013 17.2006C22.553 16.8489 22.7506 16.3718 22.7506 15.8744C22.7506 15.3769 22.553 14.8999 22.2013 14.5481C21.8495 14.1964 21.3724 13.9987 20.875 13.9987C20.3776 13.9987 19.9005 14.1964 19.5487 14.5481C19.197 14.8999 18.9994 15.3769 18.9994 15.8744C18.9994 16.3718 19.197 16.8489 19.5487 17.2006C19.9005 17.5524 20.3776 17.75 20.875 17.75ZM7.75 15.875C7.75 15.6288 7.7015 15.385 7.60727 15.1575C7.51305 14.93 7.37494 14.7233 7.20083 14.5492C7.02672 14.3751 6.82002 14.237 6.59253 14.1427C6.36505 14.0485 6.12123 14 5.875 14C5.62877 14 5.38495 14.0485 5.15747 14.1427C4.92998 14.237 4.72328 14.3751 4.54917 14.5492C4.37506 14.7233 4.23695 14.93 4.14273 15.1575C4.0485 15.385 4 15.6288 4 15.875C4 16.3723 4.19754 16.8492 4.54917 17.2008C4.90081 17.5525 5.37772 17.75 5.875 17.75C6.37228 17.75 6.84919 17.5525 7.20083 17.2008C7.55246 16.8492 7.75 16.3723 7.75 15.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataAutomation;
