import React from "react";

const X = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M7.64998 7.65004C7.34999 7.95003 6.84997 7.95003 6.54998 7.65004L3.99999 5.10005L1.45 7.65004C1.15001 7.95003 0.64998 7.95003 0.349991 7.65004C0.0500029 7.35006 0.0500029 6.85003 0.349991 6.55004L2.89998 4.00005L0.349991 1.45006C0.0500029 1.15007 0.0500029 0.650041 0.349991 0.350052C0.64998 0.0500639 1.15001 0.0500639 1.45 0.350052L3.99999 2.90004L6.54998 0.350052C6.84997 0.0500639 7.34999 0.0500639 7.64998 0.350052C7.94997 0.650041 7.94997 1.15007 7.64998 1.45006L5.09999 4.00005L7.64998 6.55004C7.94997 6.85003 7.94997 7.35002 7.64998 7.65004Z" />
    </svg>
  );
};

export default X;
